<template>
  <div class="w-full">

    <div class="relative">
      <div class="absolute" v-if="$slots.title">
        <slot name="title" />
      </div>
      <div class="flex flex-row float-right gap-3">

        <FormKit placeholder="Search" v-if="searchable" v-model="search" />

        <div class="flex gap-2 content-center" v-if="paginate">
          <IconBase class="w-5 h-5 cursor-pointer grid self-center" :class="!hasPreviousPage ? 'text-gray-400 cursor-not-allowed' : undefined" @click="changePaginationSite(false)">
            <ChevronLeft />
          </IconBase>

          <div class="flex gap-3">
            <span>Page</span>
            <input v-model="pagination.site" class="border border-gray px-2 w-16 h-6 text-right">
            <span v-if="maxPages > 1" v-text="` / ${maxPages}`" />
          </div>

          <IconBase class="w-5 h-5 cursor-pointer grid self-center" :class="!hasNextPage ? 'text-gray-400 cursor-not-allowed' : undefined" @click="changePaginationSite(true)">
            <ChevronRight />
          </IconBase>
        </div>

        <IconBase class="w-4 h-4 cursor-pointer self-center" @click="createEntry" v-if="onCreate">
          <IconPlus />
        </IconBase>

        <img class="w-4 cursor-pointer" src="@/assets/icons/wflwr/refresh.svg" @click="reload" v-if="resource && reloadable" />

      </div>
    </div>
    <div class="wrapper">
      <table class="table">
        <thead>
          <tr>
            <th v-if="typeof($props) !== 'string'" v-for="(column, columnIndex) in $columns" :key="column" @click="filterField(columnIndex)">
              <div class="flex">
                <div :class="`grid grid-cols-${filter.index === columnIndex ? '2' : '1'} gap-0`">
                  <span v-text="formatColumnName(column)" :title="formatColumnName(column)" />
                  <div v-if="filter.index === columnIndex" class="w-5 h-5 fill-white ml-3 mt-1">

                    <div v-if="column.includes('::')">
                      <div v-if="getColumnType(column) === 'numeric'">
                        <IconArrowDown19 v-if="filter.type === 'desc'" />
                        <IconArrowUp19 v-else-if="filter.type === 'asc'" />
                      </div>
                      <div v-else-if="getColumnType(column) === 'general'" class="w-3 h-3">
                        <IconArrowDownLong v-if="filter.type === 'asc'" />
                        <IconArrowUpLong v-else-if="filter.type === 'desc'" />
                      </div>
                      <div v-else>
                        <IconArrowDownAZ v-if="filter.type === 'asc'" />
                        <IconArrowUpAZ v-else-if="filter.type === 'desc'" />
                      </div>
                    </div>
                    <div v-else>
                      <IconArrowDownAZ v-if="filter.type === 'asc'" />
                      <IconArrowUpAZ v-else-if="filter.type === 'desc'" />
                    </div>

                  </div>
                </div>
              </div>
            </th>
            <th v-else class="error" v-text="'Not found'" />
            <th v-if="$slots.actions && $rows.length > 0" v-text="' '" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, itemIndex) in formatList" :key="itemIndex">
            <td :title="element.type !== 'html' ? element.value : '-'" v-for="(element, index) in item" :key="index" @dblclick="() => rowDoubleClick(itemIndex)" @click="rowClickEntry(itemIndex, item)" :class="selectable && selectedItem.index === itemIndex ? 'active' : ''">

              <span v-if="element.value === null" v-text="'Unknown'" class="unknown" />
              <div v-else-if="element.type === 'image'">
                <img class="bt-img" loading="lazy" alt=" " :src="element.value" :data-src-normal="element.value" :data-src-fullscreen="element.fullscreenUrl" @click="openImage" @error="onError" @load="onLoad" />
                <span class="bt-img-err-msg" v-text="imageNotFound" />
              </div>
              <a v-else-if="element.type === 'url'" :href="element.value" target="_blank" v-text="element.value" />
              <a v-else-if="element.type === 'url_short'" :href="element.value" target="_blank" v-text="'Click to open'" />
              <input v-else-if="element.type === 'check'" class="check" type="checkbox" :checked="element.value || parseInt(element.value) === 1" @click="event => event.preventDefault()" />
              <div v-else-if="element.type === 'html'" v-html="element.value" />
              <span v-else v-text="element.value" />

            </td>
            <td v-if="$slots.actions">
              <slot name="actions" v-bind="{ itemIndex, item, raw: rawRows[itemIndex] }" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import IconBase from "@/components/icons/IconBase";
import {isFullscreen, off, on, toggleFullscreen} from "@/helpers/Fullscreen"
import ApiResource from "@/api/ApiResource";
import IconArrowDownAZ from "@/components/icons/wflwr/IconArrowDownAZ";
import IconArrowUpAZ from "@/components/icons/wflwr/IconArrowUpAZ";
import IconArrowDown19 from "@/components/icons/wflwr/IconArrowDown19";
import IconArrowUp19 from "@/components/icons/wflwr/IconArrowUp19";
import IconArrowDownLong from "@/components/icons/wflwr/IconArrowDown";
import IconArrowUpLong from "@/components/icons/wflwr/IconArrowUp";
import IconPlus from "@/components/icons/wflwr/IconPlus";
import ChevronLeft from "@/components/icons/wflwr/IconChevronLeft.vue";
import ChevronRight from "@/components/icons/wflwr/IconChevronRight.vue";
import IconRefresh from "@/components/icons/wflwr/IconRefresh";
import { get } from "lodash";

export default {
  name: 'BlueTable',
  components: {
    IconRefresh,
    ChevronLeft,
    ChevronRight,
    IconPlus,
    IconArrowUpLong,
    IconArrowDownLong,
    IconArrowUp19,
    IconArrowDown19,
    IconArrowUpAZ,
    IconArrowDownAZ,
    IconBase,
  },
  props: {
    paginate: { type: Boolean, required: false, default: false },
    paginateMax: { type: Number, required: false, default: 25 },
    searchable: { type: Boolean, required: false, default: false },
    sortable: { type: Boolean, required: false, default: false },
    reloadable: { type: Boolean, required: false, default: false },
    selectable: { type: Boolean, required: false, default: false },
    onCreate: { type: Function, required: false },
    onDelete: { type: Function, required: false },
    ignoreSortColumns: { type: Array, required: false, default: [] },
    rows: { type: Array, required: false, default: [] },
    columns: { type: Array, required: false, default: [] },
    rowDoubleClick: { type: Function, required: false, default: () => {} },
    rowClick: { type: Function, required: false, default: () => {} },
    resource: { type: ApiResource, required: false, default: null },
    resourceParams: { type: Object, required: false, default: {} },
    imageNotFound: { type: String, required: false, default: 'Image not found!' },
  },
  data() {
    return {
      search: '',
      pagination: {
        site: 1,
      },
      filter: {
        index: -1,
        type: 'desc',
      },
      selectedItem: {
        index: -1,
        item: null,
      },
      dialogs: {
        deleteConfirm: {
          open: false,
        },
      },
      rawRows: {},
      values: {
        rows: this.rows,
        columns: this.columns,
        meta: null,
        reloadLocked: false,
      },
    }
  },
  async mounted() {
    on(this.fullscreenListen)
    if (this.resource) await this.fetchFromResource()
  },
  beforeUnmount() {
    off(this.fullscreenListen)
  },
  computed: {
    $columns() {
      return this.resource ? this.values.columns : this.columns
    },
    $rows() {
      return this.resource ? this.values.rows : this.rows
    },
    formatList() {
      if (this.pagination.site > this.maxPages)
        this.pagination.site = 1

      let filteredRows = this.search.length > 0 ? this.$rows.filter(rows => {
        for (const row of rows) {
          if (row !== null && row.value !== null &&
              row.value.toString().toLowerCase().includes(this.search.toLowerCase()))
            return true
        }
        return false
      }) : this.$rows

      if (this.paginate && this.search.length === 0)
        filteredRows = this.resource ?
            filteredRows : this.loadList(filteredRows,
            (this.pagination.site - 1) * this.paginateMax,
            this.paginateMax * this.pagination.site)

      if (this.sortable && this.filter.index !== -1) {
        filteredRows = filteredRows.sort((a, b) => {
          const valueA = a[this.filter.index].value
          const valueB = b[this.filter.index].value
          if (!valueA || !valueB)
            return 0

          if (this.is(valueA, 'number') && this.is(valueB, 'number'))
            return this.filter.type === 'asc' ? valueA - valueB : valueB - valueA

          return this.filter.type === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
        })
      }

      return filteredRows
    },
    hasNextPage() {
      return this.pagination.site < this.maxPages
    },
    hasPreviousPage() {
      return this.pagination.site > 1
    },
    maxPages() {
      const isResourceAndHasMeta = this.resource !== null && this.values.meta !== null;
      if (isResourceAndHasMeta) {
        const { per_page: perPage, total } = this.values.meta;
        return total && perPage ? Math.ceil(total / perPage) : 1;
      }
      return Math.ceil(this.$rows.length / this.paginateMax);
    },
  },
  watch: {
    async 'pagination.site'(newValue, oldValue) {
      newValue = typeof(newValue) !== 'number' ? parseInt(newValue) : newValue
      if (newValue > this.maxPages || newValue <= 0 || newValue === null) {
        this.pagination.site = oldValue
      } else if (newValue !== oldValue) {
        if (this.resource) await this.fetchFromResource()
      }
    }
  },
  methods: {
    changePaginationSite(increment) {
      if (increment) {
        if (this.hasNextPage) {
          this.pagination.site++;
        }
      } else {
        if (this.hasPreviousPage) {
          this.pagination.site--;
        }
      }
    },
    async reload() {
      if (this.values.reloadLocked)
        return

      this.values.reloadLocked = true
      setTimeout(() => this.values.reloadLocked = false, 1000 * 5)

      if (this.resource) await this.fetchFromResource()
    },
    async fetchFromResource() {
      const { meta, data, fields } = await this.resource.list({
        limit: this.paginateMax,
        page: this.pagination.site,
        ...this.$props.resourceParams,
      })
      this.toBlueTable(fields || [], data || [])
      this.values.meta = meta
    },
    toBlueTable(columns, rows) {
      this.values.rows = []
      this.values.columns = []

      const columnFields = [];
      const filteredColumns = columns.filter(column => column.enabled || true)
      for (let count = 0; count < filteredColumns.length; count++) {
        const column = filteredColumns[count]
        const field = column.resourceField ?? column.field;
        columnFields.push({
          key: field,
          isAttribute: field.startsWith('attributes.'),
          isRelation: field.startsWith('relationships.'),
        })
        this.values.columns.push(column.label)
        if ((column.sortable !== null && column.sortable !== undefined) && !column.sortable)
          this.ignoreSortColumns.push(count)
      }

      for (let [index, row] of rows.entries()) {
        let tree = []

        columnFields.forEach(field => {
          const key = field.key;
          const value = get(row, key);

          tree.push({
            value: value,
            type: columns[key] ? columns[key].type || typeof value : typeof value,
          })
        });

        this.values.rows.push(tree)
        this.rawRows[index] = row;
      }
    },
    createEntry() {
      this.onCreate()
      this.selectedItem.index = -1
      this.selectedItem.item = null
    },
    deleteEntry() {
      this.dialogs.deleteConfirm.open = false
      if (this.onDelete(this.selectedItem.index, this.selectedItem.item)) {
        this.selectedItem.index = -1
        this.selectedItem.item = null
      }
    },
    rowClickEntry(index, item) {
      this.rowClick(index, item)

      if (this.selectedItem.item !== null && this.selectedItem.index === index) {
        this.selectedItem.index = -1
        this.selectedItem.item = null
        return
      }
      this.selectedItem.index = index
      this.selectedItem.item = item
    },
    getColumnType(column) {
      return column.split("::")[0]
    },
    formatColumnName(column) {
      let temp = column.replaceAll("_", "")
      if (temp.includes("::")) {
        const values = temp.split("::")
        temp = temp.replaceAll(`${values[0]}::`, '')
      }
      return temp
    },
    is(value, key) {
      return typeof(value) === key
    },
    loadList(list, from, to) {
      const array = []
      for (let count = from;  count < to; count++) {
        const item = list[count]
        if (!(item === null || item === undefined))
          array.push(item)
      }
      return array
    },
    filterField(index) {
      if (!this.sortable || this.ignoreSortColumns.includes(index))
        return

      if (this.filter.index === index && this.filter.type === 'desc') {
        this.filter.index = -1
        return
      }

      if (this.filter.index !== index)
        this.filter.type = 'desc'

      this.filter.index = index
      this.filter.type = this.filter.type === 'asc' ? 'desc' : 'asc'
    },
    onError(event) {
      event.target.parentElement.classList.add('img-err')
    },
    onLoad(event) {
      event.target.parentElement.classList.remove('img-err')
    },
    openImage(event) {
      toggleFullscreen(event.target)
    },
    fullscreenListen(event) {
      event.target.src = isFullscreen() ? event.target.dataset.srcFullscreen : event.target.dataset.srcNormal
    },
  }
}
</script>

<style scoped lang="scss">
.bt-img::backdrop {
  background: rgb(0, 38, 85, .5);
}

.bt-img-err-msg {
  display: none;
}

.img-err > .bt-img-err-msg {
  display: inline;
}

.check {
  padding: 10px;
  border: none;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.15s ease-in-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
  margin-left: 30px;
}

.wrapper {
  display: inline-block;
  overflow: auto;
  width: 100%;
}

.table {
  overflow-x: scroll;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-top: 10px;

  tbody *, thead * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bt-column {
    word-wrap: break-word;
    min-width: 150px;
  }

  a {
    color: #002655;
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: 5px;
  }

  .unknown {
    color: rgb(180, 35, 35);
  }

  th {
    padding: 10px 15px 10px 15px;
    background-color: #0c2b52;
    color: white;
    text-transform: uppercase;

    min-width: 4rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  th.error {
    background-color: #e22323;
  }

  tr:nth-child(even) {
    background: #f1f1f1;
    transition: background 0.125s ease-in-out;

    &:hover:not(.active) {
      background: #fff;
    }
  }

  tr {
    cursor: pointer;
    background: #fff;
    transition: all 0.125s ease-in-out;

    &:hover:not(.active) {
      background: #f1f1f1;
    }
  }

  td {
    padding: 8px;
    max-width: 10rem;
    width: auto;
    &.active {
      font-weight: 600;
    }
  }
}
</style>