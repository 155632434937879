<template>
  <Modal :title="title" :close="close">
    <template v-slot:icon>
      <IconPencil />
    </template>
    <template #content>
      <BlueTable
          paginate
          :paginate-max="5"
          :resource="articleResource"
          :resource-params="{
            with: 'event,options',
            ...((isEdit && component) ? ({
            'f[component]': component,
            }) : {}),
          }"
      >
        <template #actions="{ raw: article }">
          <FormKit
              type="button"
              label="Select"
              @click="() => selectArticle(article)"
          />
        </template>
      </BlueTable>

      <hr>

      <div class="mt-2 grid auto-cols-2 grid-flow-col gap-2">
        <FormKit
            id="articleIdField"
            label="Article ID"
            readonly
        />
        <FormKit
            label="Option"
            type="select"
            :disabled="selectedArticle === null"
            :options="articleOptions"
            v-model="selection.option"
            v-if="isSelectedComponent(['tshirt-form'])"
        />
      </div>

      <InfoBox class="mt-3" v-if="isSelectedComponent(['tshirt-form'])">
        If an option has been selected for the article, it will be used to update the “more” field in the participant.
        In addition, when editing an article, the old option is replaced with the new selected option.
        When adding a new article, this option is assigned to the article by the participant.
      </InfoBox>
    </template>
    <template #footer>
      <FormKit
          type="button"
          :label="isEdit ? 'Change article' : 'Add article'"
          :disabled="selectedArticle === null"
          @click="modifyArticle"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Layout/Modal'
import IconPencil from "@/components/icons/wflwr/IconPencil.vue";
import BlueTable from "@/components/table/BlueTable.vue";

import ArticleApi from "@/api/article/article";
import ParticipantArticle from "@/api/participant/participantArticle";
import {confirmWarning} from "@/components/Modals";
import InfoBox from "@/components/InfoBox.vue";

export default {
  name: "ModifyParticipantArticleModal",
  components: {
    InfoBox,
    BlueTable,
    Modal,
    IconPencil,
  },
  props: {
    availableComponents: Array,
    article: {
      type: [Object, null],
      default: null,
    },
    participant: {
      type: Object,
      required: true,
    },
    close: Function,
    onSuccess: Function,
  },
  data() {
    return {
      selection: {
        article: null,
        option: null,
      },
      component: this.article?.relationships?.article?.attributes?.component,
      articleResource: ArticleApi,
    }
  },
  mounted() {
    document.body.addEventListener('keydown', this.keyDown)
  },
  beforeUnmount() {
    document.body.removeEventListener('keydown', this.keyDown)
  },
  computed: {
    isEdit() {
      return this.article !== null;
    },
    title() {
      return this.isEdit ? 'Edit Participant Article' : 'Add Participant Article';
    },
    selectedArticle() {
      return this.selection.article;
    },
    articleOptions() {
      const article = this.selectedArticle;
      if (!article) {
        return [];
      }
      return [
        { value: null, label: 'No option' },
        ...article.relationships.options.map(({ id, attributes: { name } }) => ({
          value: id,
          label: name,
        })),
      ];
    },
  },
  methods: {
    selectArticle(article) {
      if (this.selection.article === article) {
        return;
      }
      this.selection.article = article;
      this.$formkit.get('articleIdField').input(article.id);
    },
    modifyArticle() {
      if (this.isEdit) {
        confirmWarning(
            'Change Participant Article',
            'You are about to overwrite an article from this participant. Do you really want to continue?',
            confirmed => {
              if (confirmed) {
                this.processArticleModify();
              }
            });
        return;
      }
      this.processArticleModify();
    },
    processArticleModify() {
      const articleId = this.selection.article.id;
      const articleOptionId = this.selection.option;

      const promise = this.isEdit ?
          ParticipantArticle.updateArticle(this.article.id, articleId, articleOptionId) :
          ParticipantArticle.createArticle(this.participant.id, articleId, articleOptionId);

      const notificationId = this.$notification.loading(this.title, 'Request is being processed...');
      promise.then(() => {
        this.$notification.updateNotification(notificationId, {
          type: 'success',
          message: this.isEdit ? 'The article has been edited' : 'The article has been added',
          duration: 3000,
        });
        this.close();
        this.onSuccess();
      }).catch(error => {
        this.$notification.updateNotification(notificationId, {
          type: 'error',
          message: error.response?.data ?? error,
        });
      });
    },
    isSelectedComponent(names) {
      const article = this.selectedArticle;
      if (!article) {
        return false;
      }
      return names.includes(article.attributes.component);
    },
    keyDown(event) {
      if (event.keyCode === 27) {
        this.close()
      }
    },
  },
}
</script>
