import Resource from "@/api/Resource";
import Article from "@/models/article/article";

class ArticleApi extends Resource {}

export default new ArticleApi('articles', Article, null, {
    create: ['models.article.store'],
    update: ['models.article.update'],
    remove: ['models.article.delete']
});
