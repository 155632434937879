<template>
  <Modal
      title="Download CustomReport"
      :close="close"
  >
    <template v-slot:icon>
      <IconDownload />
    </template>
    <template v-slot:content>
      <FormKit type="form" id="form" :actions="false">
        <FormKitSchema :schema="formkitSchema" />
      </FormKit>
    </template>
    <template v-slot:footer>
      <FormKit type="button" @click="downloadReport" :disabled="isProcessing">
        {{ isProcessing ? 'Processing...' : 'Download' }}
      </FormKit>
    </template>
  </Modal>
</template>

<script>
import customReportDownloadMixin from "@/applications/CustomReport/mixins/customReportDownloadMixin";

import { FormKitSchema } from "@formkit/vue";
import Modal from '@/components/Modals/Layout/Modal';
import IconDownload from "@/components/icons/wflwr/IconDownload.vue";

export default {
  name: 'DownloadCountryCustomReportModal',
  mixins: [customReportDownloadMixin],
  components: {
    FormKitSchema,
    Modal,
    IconDownload,
  },
  props: {
    customReport: Object,
    close: Function,
  },
  computed: {
    formkitSchema() {
      return this.customReport.attributes.fields
          .map(({ field, type, label, default: defaultValue, ...rest }) =>
              this.inputProps(field, type, label, defaultValue, rest));
    },
    countries() {
      return [
        { label: 'Select a country', value: null },
        ...this.$store.state.global.countries.map(({ attributes }) => ({
          label: attributes.name,
          value: attributes.iso2,
        })),
      ];
    },
  },
  methods: {
    inputProps(name, type, label, defaultValue, rest) {
      const props = {
        name,
        label,
        ...(!defaultValue ? ({
          validation: 'required',
        }) : ({
          value: defaultValue,
        })),
        validationVisibility: 'live',
        ...rest,
      };
      switch (type) {
        case 'country_select':
          props['$formkit'] = 'select';
          props['options'] = this.countries;
          break;
        default:
          props['$formkit'] = type;
          break;
      }
      return props;
    },
    downloadReport() {
      const form = this.$formkit.get('form');
      if (!form.context.state.valid) {
        this.$notification.warning('Download CustomReport', 'Please complete all required fields');
        return;
      }

      this.downloadCustomReport(this.customReport.id, form.value, isSuccess => {
        if (isSuccess) {
          this.close();
        }
      });
    },
  },
}
</script>