import Model from '../Model'
import Participant from "@/models/participant/Participant";
import Event from "@/models/event/Event";

export default class ParticipantArticle extends Model {
    static relationMapping = {
        'participant': Participant,
        'participantEvent' : Event
    }
}
