import {isLoggedIn} from "@/router/guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'pushNotifications',
                        name: 'pushNotifications',
                        component: () => import('../applications/PushNotifications/PushNotifications.vue'),
                    },
                ],
            }
        ]
    }
]