import CustomReportApi from "@/api/customReport/customReport";
import { downloadBlob } from "@/helpers/FileHelper";

export default {
    data() {
      return {
          isProcessing: false,
      }
    },
    methods: {
        downloadCustomReport(id, data = {}, callback = () => {}) {
            if (this.isProcessing) {
                return;
            }

            const notificationId = this.$notification.loading(
                'Download CustomReport',
                'The download of the report may take a while...',
            );

            this.isProcessing = true;
            CustomReportApi.download(id, data, {
                responseType: 'blob',
            }).then(response => {
                const fileName = response.headers["content-disposition"].split("filename=")[1];

                downloadBlob(new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }), fileName);

                this.$notification.updateNotification(notificationId, {
                    type: 'success',
                    message: 'Successfully downloaded custom report',
                    duration: 3000,
                });

                callback(true);
            }).catch(error => {
                callback(false);
                this.$notification.updateNotification(notificationId, {
                    type: 'error',
                    message: error,
                });
            }).finally(() => {
                this.isProcessing = false;
            });
        },
    },
}