<template>
  <div class="border border-l-[16px] grow flex-1" :class="wrapperClass">
    <div class="flex justify-between">
      <div class="items-stretch bg-gray-100 grow p-4">
        <slot></slot>
      </div>
      <div class="w-28 bg-gray-200 flex items-center justify-center flex-none" :style="{minHeight: `${minHeight}px`}">
        <slot name="insidebox" :icon-color="typeColor">
          <IconBase class="w-10 h-10" :icon-color="typeColor">
            <slot name="icon"><IconWarning/></slot>
          </IconBase>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>

import IconWarning from "./icons/wflwr/IconWarning";
import IconBase from "./icons/IconBase";
export default {
  components: {IconBase, IconWarning},
  props: {
    type: {
      type: String,
      default: 'info'
    },
    minHeight: {
      type: Number,
      default: 112
    }
  },
  computed: {
    typeColor(){
      switch (this.type){
        case 'error':
          return '#e40039'
        case 'warning':
          return '#ffb500'
          break;
        case 'info':
        default:
          return '#002655'
      }
    },
    wrapperClass(){
      switch (this.type){
        case 'error':
          return 'border-[#e40039]'
        case 'warning':
          return 'border-[#ffb500]'
        case 'info':
        default:
          return 'border-cli-primary'
      }
    }
  }
}
</script>
