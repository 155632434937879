import Resource from './../Resource'
import {manage} from "@/api/Client";
import ParticipantArticle from "@/models/participant/ParticipantArticle";

class ParticipantArticleApi extends Resource {
    async getBoosters() {
        const { data: { data } } = await manage.get(`participantArticles/booster`)
        return data
    }

    createArticle(participantId, articleId, articleOptionId) {
        return manage.post('participantArticles', {
            articleId,
            participantId,
            ...(articleOptionId ? { articleOptionId } : {}),
        });
    }

    updateArticle(articleId, newArticleId, articleOptionId) {
        return manage.put(`participantArticles/${articleId}`, {
            articleId: newArticleId,
            ...(articleOptionId ? { articleOptionId } : {}),
        });
    }
}

export default new ParticipantArticleApi('participantArticles', ParticipantArticle, null, {
    create: ['models.participantArticle.store'],
    update: ['models.participantArticle.update'],
    remove: ['models.participantArticle.delete']
})
