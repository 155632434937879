export function enterFullscreen(element) {
    (async () => {
        document.fullscreenEnabled =
            document.fullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.documentElement.webkitRequestFullScreen

        if (!document.fullscreenEnabled) {
            console.error("Fullscreen in this Browser is not supported/enabled.")
            return
        }

        if (element.requestFullscreen) {
            await element.requestFullscreen()
            return
        }
        if (element.mozRequestFullScreen) {
            await element.mozRequestFullScreen()
            return
        }

        if (element.webkitRequestFullScreen)
            await element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
    })()
}

export function leaveFullscreen() {
    (async () => {
        if (document.fullscreenElement) {
            await document.exitFullscreen()
        }
    })()
}

export function toggleFullscreen(element) {
    if (isFullscreen()) {
        leaveFullscreen()
        return
    }
    enterFullscreen(element)
}

export function isFullscreen() {
    return document.fullscreen
}

export function on(listener) {
    document.addEventListener('fullscreenchange', listener)
}

export function off(listener) {
    document.removeEventListener('fullscreenchange', listener)
}

export default {
    enterFullscreen,
    leaveFullscreen,
    toggleFullscreen,
    isFullscreen,
    on,
    off
}